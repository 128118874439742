import { LitElement, css, html } from 'lit'

class CustomPicture extends LitElement {
  static properties = {
    picture: { type: Object },
    altText: { type: String },
    width: { type: Number },
    height: { type: Number }
  }

  static styles = css`
    .image {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  `

  render () {
    return html`
      <picture>
        ${Object.entries(this.picture.sources).map(source => {
          return html`
            <source type="${source[0]}"
                    srcset="${this.getPictureSource(source[1])}"
                    sizes="${this.picture.media}">
          `
        })}
        <img class="image"
             alt="${this.altText}"
             width="${this.width}"
             height="${this.height}"
             loading="lazy">
      </picture>
    `
  }

  getPictureSource (picture) {
    let source = ''
    if (picture !== null) {
      for (const [px, url] of Object.entries(picture)) {
        source += `${url} ${px}w, `
      }
    }
    return source
  }
}

customElements.define('custom-picture', CustomPicture)
